import { APICore } from "./apiCore";

const api = new APICore();

// account
async function login(params: { username: string; password: string }) {
  const baseUrl = "/login";
  return await api.create(
    `${baseUrl}`,
    { email: params.username, password: params.password },
    "POST"
  );
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {}, "POST");
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register";
  return api.create(`${baseUrl}`, params, "POST");
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forgotPassword";
  return api.create(`${baseUrl}`, params, "POST");
}

export { login, logout, signup, forgotPassword };
